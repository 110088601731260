// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.@{portal-prefix}-header-wrap{
  color: #fff;
  height: 44px;
  background: @hd-bg-color;
  display: flex;
  align-items: center;
  .@{portal-prefix}-header-wrap-title-area{
    display: flex;
    align-items: center;
    height: 100%;
    flex-shrink: 0;
    .@{portal-prefix}-header-wrap-title-icon{
      padding:0 14px;
      font-size: 22px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-shrink: 0;
      &:hover{
        background: @hd-bg-hover-color;
      }
      &.active{
        background: @hd-bg-hover-color;
      }
    }
    .@{portal-prefix}-header-wrap-title-text{
      margin-left: 20px;
      margin-right: 47px;
      padding-right:13px;
      height: 100%;
      display: flex;
      align-items: center;
      font: @normal_font;
      flex-shrink: 0;
      user-select: none;
      .@{portal-prefix}-logo{
        margin-top: 4px;
        margin-left: -4px;
      }
      .@{portal-prefix}-logo-hidden{
        display: none;
      }
    }
  }
  .@{portal-prefix}-header-wrap-right-area{
    display: flex;
    flex-shrink: 0;
  }
  :global{
    .ant-drawer{
      top:44px;
    }
    .anticon{
      display: block;
    }
  }
}

.@{portal-prefix}-header-wrap-other-them{
  color: rgb(0, 0, 0);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.05);
  z-index: 2;
  background: @hd-bg-color-other;
  .@{portal-prefix}-header-wrap-title-area{
    .@{portal-prefix}-header-wrap-title-icon{
      &:hover{
        background: @hd-bg-hover-color-other;
      }
      &.active{
        background: @hd-bg-hover-color-other;
      }
    }
  }
}
