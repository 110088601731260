// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.function-search-page-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .left-menu {
    width: 240px;
    padding-top: 41px;
    box-shadow: 0px 8px 10px 2px rgba(227, 231, 237, 0.5);
    overflow-y: auto;
    .title-box {
      width: 100%;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      padding-left: 20px;
      height: 40px;
      .function-icon {
        margin-right: 8px;
        display: inline-block;
        span {
          width: 14px;
          height: 15px;
        }
      }
    }
    .menu-outer-box {
      width: 100%;
      height: calc(100% - 40px);
      :global {
        .ant-menu-item::before {
          display: none;
        }
      }
    }
  }
  .context-box {
    width: calc(100% - 240px);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    .documents {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .documents-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        padding-left: 30px;
        .documents-icon {
          margin-right: 8px;
          display: inline-block;
          span {
            width: 14px;
            height: 15px;
          }
        }
      }
      .documents-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding-left: 30px;
        padding-top: 25px;
      }
    }
  }
  // 新增尽情期待
  .documents-item {
    display: flex;
    flex-direction: column;
    padding: 18px 22px 16px 20px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(227, 231, 237, 0.8);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 20px;
    margin-bottom: 20px;
    width: 460px;
    height: 118px;
    border-radius: 6px;
    cursor: pointer;
    span {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      color: rgba(0, 0, 0, 0.3);
      font-size: 16px;
    }
    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    }
  }
}
