// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.content {
  :global {
    .ant-tree-treenode:hover {
      background: rgba(227, 231, 237, 0.5) !important;
      background: var(--ant-primary-1) !important;
      color: var(--ant-primary-color) !important;
    }
    .ant-tree-treenode-draggable {
      width: 232px !important;
      height: 46px !important;
      .ant-tree-node-content-wrapper {
        height: 100% !important;
        line-height: 50px;
      }
      .ant-tree-iconEle {
        margin-top: 12px;
        margin-right: 3px;
      }

      .ant-tree-draggable-icon {
        position: fixed;
        margin-left: 200px;
        margin-top: 16px;
        line-height: 46px;
        color: transparent;
      }
      .ant-tree-drop-indicator {
        display: none;
      }
    }

    .ant-tree-node-content-wrapper:hover {
      background: transparent !important;
    }
    .ant-tree-treenode.dragging:after {
      border-color: rgba(227, 231, 237, 0.5) !important;
    }

    .ant-tree-treenode-selected {
      // background: #e2e8f6; //var(--antd-primary-bg-base);
      background: var(--ant-primary-1);

      position: relative;
      color: var(--ant-primary-color);

      .ant-tree-node-content-wrapper {
        // color: #1d2129; //var(--ant-primary-color);
        color: var(--ant-primary-color);
        height: 100% !important;
        line-height: 50px;
        background: transparent !important;
      }
      .ant-tree-draggable-icon {
        position: none;
      }
      .ant-tree-iconEle {
        margin-top: 12px;
        margin-right: 3px;
      }
      /*   &::before {
        position: absolute;
        width: 2px;
        height: 46px;
        background: var(--ant-primary-color);
        top: 0px;
        left: 1px;
        content: '';
      } */
      &::after {
        position: absolute;
        width: 20px;
        height: 30px;
        background: url('../../../../../src/asset/sort.svg') no-repeat;
        top: 15px;
        right: 10px;
        content: '';
      }
    }
    .ant-tree-treenode-selected:hover {
      background: rgba(227, 231, 237, 0.6); //var(--antd-primary-bg-base) !important;
    }
  }
}
