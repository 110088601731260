// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.spinBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :global {
    .ant-spin-nested-loading >div>.ant-spin {
      max-height: 100%;
    }
    .ant-spin-nested-loading {
      width: 100%;
      height: 100%;
    }
    .ant-spin-container{
      width: 100%;
      height: 100%;
    }
  }
}

.loading {
  display: inline-block;
  width: 48px;
  height: 48px;
  font-size: 48px !important;
  position: relative;
  margin: 0;
  animation-duration: 5.6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: nextVectorRoute;
}
.loading .dot {
  position: absolute;
  margin: auto;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--ant-primary-color);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1.4s;
}
.loading .dot:first-child {
  top: 0;
  bottom: 0;
  left: 0;
  animation-name: nextVectorDotsX;
}
.loading .dot:nth-child(2) {
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.8;
  animation-name: nextVectorDotsY;
}
.loading .dot:nth-child(3) {
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  animation-name: nextVectorDotsXR;
}
.loading .dot:nth-child(4) {
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  animation-name: nextVectorDotsYR;
}
@keyframes nextVectorDotsYR {
  25% {
    bottom: 0;
  }
  45%,
  50% {
    bottom: 16.8px;
    width: 14.4px;
    height: 14.4px;
  }
  90% {
    bottom: 0;
    height: 12px;
    width: 12px;
  }
}
@keyframes nextVectorDotsXR {
  25% {
    right: 0;
  }
  45%,
  50% {
    right: 16.8px;
    width: 14.4px;
    height: 14.4px;
  }
  90% {
    right: 0;
    height: 12px;
    width: 12px;
  }
}
@keyframes nextVectorDotsY {
  25% {
    top: 0;
  }
  45%,
  50% {
    top: 16.8px;
    width: 14.4px;
    height: 14.4px;
  }
  90% {
    top: 0;
    height: 12px;
    width: 12px;
  }
}
@keyframes nextVectorDotsX {
  25% {
    left: 0;
  }
  45%,
  50% {
    left: 16.8px;
    width: 14.4px;
    height: 14.4px;
  }
  90% {
    left: 0;
    height: 12px;
    width: 12px;
  }
}
@keyframes nextVectorRoute {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(90deg);
  }
  30% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  55% {
    transform: rotate(270deg);
  }
  75% {
    transform: rotate(270deg);
  }
  80% {
    transform: rotate(1turn);
  }
  to {
    transform: rotate(1turn);
  }
}
