// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.error-box {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-content {
  display: flex;
  flex-direction: row;
}
.error-left-img {
  width: 295px;
  height: 216px;
  background-image: url('../../asset/error.png');
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 110px;
}
.title-text {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: rgba(0,0,0,0.85);
}
.detail-text {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: rgba(0,0,0,0.65);
  margin-top: 18px;
  margin-bottom: 50px;
}
.error-right-text {
  .return-button {
    width: 160px;
    height: 40px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0071FE;
    border-radius: 20px;
    background-color: #fff;
    border: 0;
    cursor: pointer;
    border: 1px solid #0071FE;
  }
  button:not([disabled]):active, button:not([disabled]):hover {
    outline: 0;
    box-shadow: none;
    border: 1px solid #096dd9;
    color: #096dd9;
  }
  button:active {
    color: #096dd9;
    border: 1px solid #096dd9;
    background: #fff;
    outline: 0;
    box-shadow: none;
    border: 0;
  }
}