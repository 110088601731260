// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.FormWindow {
    .ant-modal {
        height: 100% !important; //高度撑满父元素 否则定位有问题
        pointer-events: auto;
        .ant-modal-content {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
    .ant-modal-close {
        color: rgba(0, 0, 0, 0.45);
        &:hover {
            color: rgba(0, 0, 0, 0.45);
        }
        .ant-modal-close-x {

            line-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .ant-modal-header {
        color: #ffffff;
        padding: 9px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        position: relative;
        &::before {
            content: '';
            width: 4px;
            height: 12px;
            background-color: var(--primary-color, #0081cc);
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -6px;
        }
        .ant-modal-title {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            line-height: 22px;
            .customTitle {
                padding-right: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .anticon {
                    color: rgba(0, 0, 0, 0.45);
                }
            }
        }
    }
    .ant-modal-body {
        flex: 1;
        padding: 24px;
        overflow: auto;
    }
    .ant-modal-footer {
        padding: 12px 24px;
    }
    &.CscecFormWindow {
        .ant-modal-header {
            background-color: var(--primary-color, #0081cc);
            .ant-modal-title {
                color: #ffffff;
            }
        }
        .ant-modal-close {
            color: #ffffff;
            &:hover {
                color: #ffffff;
            }
        }
    }
    &.noHeaderLine {
        .ant-modal-header {
            &::before {
                width: 0;
            }
        }
    }
    .noPadding {
        .ant-modal-body {
            padding: 0;
        }
    }
    .okBtnAtLeftStyle {
        .ant-modal-footer {
            .ant-btn-default {
                float: right;
                margin-left: 8px;
            }
        }
    }
}
