// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.@{portal-prefix}-tags-wrap{
  min-width: 0;
  flex:1;
  :global{
    .ant-tabs{
      height: 100%;
    }

    .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn,.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn{
      outline: none;
    }


    .ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,.ant-tabs-card.ant-tabs-bottom >.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,.ant-tabs-card.ant-tabs-top >div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,.ant-tabs-card.ant-tabs-bottom >div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab{
      margin-left:0
    }
    .ant-tabs-card >.ant-tabs-nav,.ant-tabs-card >div>.ant-tabs-nav{
      background: #f5f7f9;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
      margin: 0;
      height:40px;
      .@{portal-prefix}-tags-title{
        height: 100%;
        padding:0 54px 0 16px;
        display: flex;
        align-items: center;
      }
      .portal-tags-title-index{
        padding:0 19px;
      }
      .ant-tabs-tab{
        padding:0;
        border:0;
        border-radius: 0;
        .ant-tabs-tab-btn{
          height: 100%;
        }
      }
      .ant-tabs-tab:first-child{
        background: @hd-tags-bg-color;
        background-color: var(--ant-primary-1);
      }
      .ant-tabs-tab:not(.ant-tabs-tab-active){
        background: rgba(245, 247, 249, 1);
        .ant-tabs-tab-remove{
          opacity: 0;
          transition: opacity .3s;
        }
        &:hover{
          background: @hd-tags-bg-color;
          .ant-tabs-tab-btn{
            color: initial;
          }
          .ant-tabs-tab-remove{
            opacity: 1;
          }
        }
        &:hover:before,&:hover + .ant-tabs-tab:before{
          opacity: 0;
        }
      }
      .ant-tabs-tab-remove{
        padding: 4px;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 0;
        top: 50%;
        transform: translate(0px, -50%);
        position: absolute;
        right:16px;
        .anticon{
          font-size: 8px;
        }
        &:hover{
          background: #EAEEF2;
        }
      }
      .ant-tabs-tab{
        font-size: 13px;
      }
      .ant-tabs-tab{
        &:before{
          content: '';
          position: absolute;
          top:11px;
          bottom:11px;
          left: 0;
          border-left:1px solid #E3E7ED;
          transition: opacity .3s;
        }
      }
      .ant-tabs-tab:first-child{
        &:before{
          display: none;
        }
      }
      .ant-tabs-tab:last-child{
        &:before{
         display: none;
        }
      }
      .ant-tabs-tab-active{
        background: @hd-tags-bg-color;
        background-color: var(--ant-primary-1);
        border-bottom:2px solid var(--ant-primary-color);
        &:before{
          display: none;
        }
      }
      .ant-tabs-tab-active + .ant-tabs-tab:before{
        opacity: 0;

      }

      &:before{
        display: none;
      }
    }
    .ant-tabs .ant-tabs-content-holder{
      //padding:10px 5px 20px 5px;
      //overflow: auto;
      .ant-tabs-content{
        // padding: 8px;
      }
      .ant-tabs-content,.ant-tabs-tabpane{
        height: 100%;
        // padding: 8px;
      }
     
    }

    .ant-tabs-extra-content{
      height: 100%;
    }
  }
  .@{portal-prefix}-tags-title{
    margin:0;
    line-height:14px;
		.tagsDashboard{
			font-weight: 400;
			.tagsDashboardText{
				padding-left: 8px;
			}
		}
    :global{
      .anticon{
        margin:0!important;
      }
    }
    &-mark{
      width:6px;
      height: 6px;
      background:  rgba(0,0,0,0.35);
      border-radius: 3px;
      float: right;
      margin-top: 4.7px;
      margin-left: 5px;
      position: absolute;
      right: 40px;
      top: 12px;
    }
    &-mark-active{
      background: var(--ant-primary-color);
   
    }
  }
  .tagsRightOperation{
    position: relative;
    display: flex;
    height: 100%;
    &:after{
      content: '';
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      border-left:1px solid #E2E7ED;
    }
    .tagsRightIcon{
      width: 25px;
      height: 25px;
      // border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover:not(.tagRightOperationDisabled){
        background: rgba(234, 238, 242, 1);
        :global{
          .anticon{
            color:#68696A
          }
        }
      }
      :global{
        .anticon{
          color: #C8C8C8;
          font-size:12px;
        }
      }

    }
    .tagsRightForwardAndBack{
      padding:0 10px;
      display: flex;
      align-items: center;
      height: 100%;
      .tagsRightBackIcon{
        margin-right: 10px;
      }
    }
    .tagsRightOperateMore{
      padding: 0 14px 0 11px;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      &:after{
        content: '';
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        border-left:1px solid #E2E7ED;
      }
    }
    .tagRightOperationDisabled{
      cursor: not-allowed;
      opacity: .5;
    }
  }
}
.@{portal-prefix}-tags-wrap-fixed{
  position:relative;
  :global{
    .ant-tabs .ant-tabs-content-holder{
      padding-left:0
    }
  }
}
.tagsRightOperationWrap{
  display: flex;
  align-items: center;
  .tagsRightOperationCloseIcon{
    margin-right: 6px;

  }
}

.tagsRightOperateDropDownMore{
  :global{
    .ant-dropdown-menu{
      padding:12px 0;
      border-radius: 4px;
      .ant-dropdown-menu-item{
        padding:8px 23px;
        line-height: 18px;
      }

    }
  }


}

.portalTagsWujieContainer{
  width: 100%;
  height: calc(100% - 40px);
  // overflow: hidden;
  // transform: translateY(-100%);
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  .portalTagsWujie{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.portalTagsWujieContainerHidden{
  visibility: hidden;

}
.@{portal-prefix}TagsAppConTextMenu{
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  width: 158px;
  li {
    margin: 0;
    padding: 4px 9px;
    line-height: 24px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background: var(--ant-primary-5);
      color: #ffffff;
    }
  }
}

.portalPin{
  position: absolute;
  right:16px;
  display: none;
  top: 54%;
  transform: translate(0px, -50%);
  svg{
    fill:var(--ant-primary-color);
    display: block;
  }
}
.portalPinShow{
  display: block;
}