// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.search-input-box {
  position: relative;
  width: calc(100% - 81px);
  .search-icon {
    font-size: var(--normal-font-size);
    color: #BFBFBF;
  }
  .voice-icon {
    font-size: 15px;
    color: rgba(0,0,0,0.65);;
  }
  :global {
    .ant-input {
      border-radius: 0;
      border-bottom: 1px solid #E3E7ED !important;
      padding-left: 30px!important;
      margin-top: 4px;
    }
    .ant-input:focus {
      border-bottom: 1px solid var(--ant-primary-color);
    }
    input::-webkit-input-placeholder {
      color: rgba(0,0,0,0.25);;
      font-size: var(--normal-font-size);
    }
    .ant-input-prefix {
      position: absolute;
      left: 9px;
      bottom: 9px;
    }
    .ant-input-suffix {
      position: absolute;
      right: 28px;
      bottom: 9px;
    }
    .ant-input-affix-wrapper {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 20px;
    }
  }
}
