// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.ability-drawer-box {
  .anticon {
    display: inline-block;
  }
  outline: none;
  :global {
    .ant-drawer-header {
      display: none;
    }
    .ant-drawer-body {
      padding: 0;
    }
    .ant-drawer-content-wrapper {
      box-shadow: none !important;
    }
    .ant-drawer-mask {
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .header-box {
    width: 100%;
    height: 51px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 1px;
    padding-left: 20px;
    .go-back {
      padding-bottom: 6px;
      cursor: pointer;
      display: flex;
      z-index: 1;

      justify-content: flex-end;
      position: relative;
      padding-left: 12px;
      .go-back-icon {
        position: absolute;
        left: 0;
        top: 40%;
        transform: translateY(-50%);

        font-size: 12px;
        /* color: #86909C; */
        color: #ccc;
      }
      .go-back-text {
        font-size: 14px;
        font-weight: 400;
        color: #1d2129;
        line-height: 20px;
        padding-left: 6px;
      }
    }
    .close-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 81px;
      margin-bottom: 10px;
      cursor: pointer;
      .arrow-icon {
        color: #bbbbbb;
        font-size: 12px;
      }
      .close-text {
        font-size: var(--normal-font-size);
        color: rgba(0, 0, 0, 0.75);
        margin-left: 5px;
      }
    }
    .toggle-button {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 17px;
      right: 24px;
      background: #F3F5FA;
      border-radius: 2px;
      width: 78px;
      height: 36px;
      align-items: center;
      justify-content: space-between;
      z-index: 2;
      .toggle-button-icon {
        width: 14px;
        height: 15px;
        color: #979797;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        &:hover{
          background: var(--ant-primary-2);
        }
      }
      .active-toggle-button-icon {
        // color: var(--ant-primary-color);
        color: #1d2129;
      }
    }
  }
  .total-content-box {
    width: 100%;
    height: calc(100% - 52px);
    display: flex;
    position: relative;
    .search-content {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1001;
      background-color: #fff;
    }
  }
}
