// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.function-menu-box {
  width: 100%;
  height: calc(100% - 201px);
  overflow-y: auto;
  box-sizing: border-box;
  border-inline-end: none !important;
  :global {
    .ant-menu-item:not(.ant-menu-item-selected):hover {
      // background-color: rgba(227, 231, 237, 0.6) !important;

      background-color: var(--ant-primary-1) !important;
      color: var(--ant-primary-color) !important;
    }
    .ant-menu-item {
      padding: 0;
      padding-left: 40px !important;
      margin: 0 !important;
      margin-bottom: 5px !important;
      height: 46px;
      width: 100%;
      border-radius: 0;
      font-size: var(--normal-font-size);
      color: rgba(0, 0, 0, 0.85);
      // color: var(--ant-primary-color);
      &::before {
        font-family: 'iconfont';
        display: block;
        content: '\e65c';
        position: absolute;
        right: 11px;
        font-size: 12px;
        color: #ccc;
      }
      &:active {
        // background-color: var(--antd-primary-bg-base) !important;
      }
    }
    .ant-menu-item-icon {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
    .ant-menu-item-selected {
      // background: #E2E8F6;
      background: var(--ant-primary-1) !important;
      color: var(--ant-primary-color);
      position: relative;
      .ant-menu-item-icon {
        color: var(--ant-primary-color);
      }
      &::after {
        display: block;
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        // background: var(--ant-primary-color);
        left: 0;
        top: 0;
      }
    }
  }
}
