// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
/*
 * @Author: ZLL
 * @Date: 2023-02-07 13:39:44
 * @LastEditors: atwlee
 * @LastEditTime: 2023-07-03 09:35:43
 * @FilePath: /erp-finance-biz-portal-react-start/src/index.less
 * @Description: 文件描述
 */
:root {
  --normal-font-size: @font_size_normal_level;
  --font-family: @font-family;
  --vp-c-brand: var(--ant-primary-color) !important;
  // 新增input公共样式
  --input-border-color: #041f4a;
  --input-shadow: rgba(4, 31, 74, 0.08);
  // select公共颜色
  --select-border-background: rgba(227, 231, 237, 0.6);
  --select-border-color: rgba(227, 231, 237, 0.8);
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--normal-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*   min-width: 1200px; */
}
#root {
  height: 100%;
  overflow: hidden;
}
ul,
li {
  list-style: none;
}

iframe {
  border: none !important;
}

.ant-drawer {
  outline: none;
}

@font-face {
  font-family: 'iconfont'; /* Project id 3839183 */
  src: url('//at.alicdn.com/t/c/font_3839183_7zc9eryst78.woff2?t=1675758316671') format('woff2'),
    url('//at.alicdn.com/t/c/font_3839183_7zc9eryst78.woff?t=1675758316671') format('woff'),
    url('//at.alicdn.com/t/c/font_3839183_7zc9eryst78.ttf?t=1675758316671') format('truetype');
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #f1f3f6;
}
::-webkit-scrollbar-track {
  background: #ffffff;
}
// 鼠标移入滚动条样式

::-webkit-scrollbar-thumb:hover {
  background-color: #c9cdd4;
}
