// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.@{portal-prefix}-header-wrap-nav-area {
  flex: 1;
  height: 100%;
  user-select: none;
  margin-right: 30px;
  overflow: hidden;

  .@{portal-prefix}-header-wrap-nav-list {
    display: flex;
    width: 100%;
    height: 100%;
    .@{portal-prefix}-header-wrap-nav-list-item {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 25px;
      margin-right: 6px;
      cursor: pointer;
      justify-content:center;
      flex-shrink: 0;
      color: rgba(255, 255, 255, 0.6);
      font-weight: 400;
      font-size: 14px;
      .@{portal-prefix}-header-wrap-nav-icon {
        margin-right: 6px;

      }

      .@{portal-prefix}-header-wrap-nav-text {
        padding-right: 3px;
      }
      &:hover {
       color: rgba(255, 255, 255, 1);
      }
    }
    .@{portal-prefix}-header-wrap-nav-active-nav {
      color: rgba(255, 255, 255, 1);
      text-shadow: 0px 0px 8px #FFFFFF;
      font-weight: 540;
    }

    .@{portal-prefix}-header-wrap-nav-list-item:last-child {
      margin-right: 0;
    }
    .@{portal-prefix}-header-more-nav-list{
      flex-shrink: 0;

    }
  }

  .@{portal-prefix}-header-wrap-nav-list-hide{
    height: 0;
    overflow: hidden;
  }
}
.@{portal-prefix}-header-wrap-nav-more-dropdown {
  .@{portal-prefix}-header-wrap-nav-more-list-item {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
    .@{portal-prefix}-header-wrap-nav-icon {
      margin-right: 6px;
      font-size: 14px;
    }
    .@{portal-prefix}-header-wrap-nav-text {
      padding-right: 3px;
    }
  }
  .@{portal-prefix}-header-wrap-nav-more-list-item:hover {
    color: var(--ant-primary-color);
  }
  .@{portal-prefix}-header-wrap-nav-active-nav {
    background: rgba(0, 0, 0, 0.04);
  }
}

.@{portal-prefix}-header-wrap-nav-area-other-them {
  .@{portal-prefix}-header-wrap-nav-list {
    .@{portal-prefix}-header-wrap-nav-list-item {
      color: rgba(0, 0, 0, 0.35);
      &:hover {
        color: rgba(0, 0, 0, 0.85);
        //background: @hd-bg-hover-color-other;
      }
    }
    .@{portal-prefix}-header-wrap-nav-active-nav {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 550;
    }
    .@{portal-prefix}-header-wrap-nav-active-nav {
      background: @hd-bg-hover-color-other;
    }
  }
}

.drop-shadow {
  filter: drop-shadow(0px 0px 5px #FFFFFF);
}
