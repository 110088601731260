// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .portal-login-left-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    .portal-login-logo-icon {
      width: 105px;
      height: 30px;
      background: url('../../../../asset/logo.svg') no-repeat;
      background-size: cover;
      padding-left: 4px;
    }
    .portal-login-logo-label {
      font-size: 18px;
      color: rgba(0,0,0,0.65);
      padding-left: 6px;
      margin-bottom: 4px;
    }
    .forget-pass-world{
      width: 64px;
      height: 21px;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      line-height: 21px;
      margin-left: 40px;
      cursor: pointer;
    }
  }
  .portal-login-right-language {
    display: flex;
    align-items: center;
    justify-content:flex-end;
    width: 20%;
    color: rgba(0, 0, 0, 0.55);
    .login{
      font-size: 13px;
      position: relative;
      cursor: pointer;
      padding-top: 1.1px;
      &::before{
        content: '';
        position: absolute;
        width: 0px;
        height: 11px;
        margin-left:30px;
        margin-top: -5.5px;
        border: 1px solid rgba(0,0,0,0.25);
      }
    }
    .language-icon {
      font-size: 14px;
      margin-right: 3px;
      margin-left: 12px;
      margin-top: 0.5px;
    }
    .portal-language-label {
      font-size: 13px;
      cursor: pointer;
      padding-top:2px;
      :global{
        .ant-select-item-option-selected{
          background:  var(--antd-primary-bg-base) !important;
        }
      }
    }
    .language-dropdown-icon {
      font-size: 11px;
      padding-left: 7px;
      padding-top:2px;
    }
  }
}