// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.container {
  width: 100%;
  height: 100%;
  background: url('../../../../asset/bigLoginBg.jpg') no-repeat;
  .header {
    width: 100%;
    height: 7%;
    line-height: 7%;
    padding: 16px 30px 15px 30px;
  }
  .container-Box {
    padding-left: 34.375%;
    padding-top: 5.76%;
    width: 100%;
    background: #ffffff;
    height: 99.929%;
    .top {
      display: flex;
      flex-direction: row;
      margin-bottom: 86px;
      .top-item {
        display: flex;
        flex-direction: row;
        font-size: 13px;
        margin-right: 26px;
        .select-iconNumber {
          position: fixed;
          width: 28px;
          height: 28px;
          border-radius: 14px;
          background: rgba(0, 0, 0, 0.25);
          margin-top: -2.75px;
        }
        .icon-Number {
          width: 22px;
          height: 22px;
          margin-top: 0px;
          margin-left: 3px;
          background: rgba(38, 51, 63, 0.2);
          border-radius: 11px;
          line-height: 22px;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          margin-right: 13px;
        }
        .name {
          font-size: 13px;
          color: rgba(0, 0, 0, 0.55);
          line-height: 22px;
          margin-right: 26px;
        }
        .line {
          width: 60px;
          height: 0px;
          margin-top: 10px;
          border: 1px dashed #a2aab1;
        }
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      .loginFormItem{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .phone-number {
          width: 400px;

          background: rgba(227, 231, 237, 0.3);
          border-radius: 4px;

        }
        .verification-code {
          display: flex;
          flex-direction: row;
          .count-down-buttonText {
            display: flex;
            flex-direction: row;
          }
          :global {
            .ant-btn-primary {
              background: rgba(227, 231, 237, 0.3);
              margin-left: 10px;
              height: 40px;
              width: 110px;
              box-shadow: none !important;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.85);
            }
            .ant-form-item-control-input-content {
              display: flex;
            }
          }
        }
        .next-step {

         .login-error {
            width: 400px;
            height: 24px;
            line-height: 21px;
            background: rgba(241, 55, 55, 0.08);
            border-radius: 2px;
            border: 1px solid rgba(248, 154, 154, 1);
            font-size: 12px;
            color: rgba(0, 0, 0, 0.85);
            padding-left: 23px;
            position: relative;
            position: absolute;
            bottom: 50px;
            &:before {
              content: '';
              position: absolute;
              margin-top: 5.3px;
              margin-left: -15px;
              width: 12px;
              height: 12px;
              background: url('../../../../asset/iconError.png');
            }
          }
          :global {
            .ant-btn-primary {
              width: 400px;
              height: 40px;
              background: rgba(223, 0, 36, 0.2);
              border-radius: 4px;
              box-shadow: none !important;
            }
          }
        }
      }
     
      .reset-finished {
        display: flex;
        flex-direction: column;
        width: 470px;
        align-items: center;
        .icon-style {
          font-size: 40px;
          color: rgba(22, 192, 92, 1);
          margin-bottom: 10px;
          padding-right: 10px;
        }
        .tip {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 20px;
        
        }
        .count-down-tip {
          font-size: 13px;
          color: rgba(0, 0, 0, 0.55);
          display: flex;
          flex-direction: row;
        }
      }
      :global{
        .ant-form-item-explain-error {
          width: 310px;
          height: 24px;
          line-height: 24px;
          background: rgba(247, 167, 70, 0.08);
          border-radius: 4px;
          border: 1px solid #fbd2a1;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.85);
          padding-left: 23px;
          margin-top: 6px;
          margin-bottom: 6px;
          position: relative;
          z-index: 9999;
          &:before {
            content: '';
            position: absolute;
            margin-top: 7.8px;
            margin-left: -14px;
            width: 12px;
            height: 10px;
            background: url('../../../../asset/warn.svg');
          }
        }

      }
    }
  }
}
