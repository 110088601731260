// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.panel-content {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  .top {
    display: flex;
    width: 100%;
    height: 78.8%;
    padding-top: 59px;
    .panel-left-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 41%;
      height: 100%;

      .panelLeftBoxDimensionalLabel {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
      }
      .panelLeftBoxDimensional {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 0 24px 0;
        .dimCodeIcon {
          width: 110px;
          height: 110px;
          background: url('../../../../asset/dimCodeIcon.png');
        }

        .dimCodeTips {
          font-size: 13px;
          color: rgba(0, 0, 0, 0.55);
          margin-top: 30px;
        }
      }
      .panelLeftBoxDimDownLoad {
        font-size: 14px;
        .down-load-button {
          width: 190px;
          height: 40px;
          background: rgba(227, 231, 237, 0.35);
          border-radius: 4px;
          color: #df0024;
          box-shadow: none !important;
        }
      }
    }
    .vertical-line {
      width: 1px;
      height: 272px;
      background-color: #e3e7ed;
    }
    .panel-right-box {
      width: 59%;
      height: 100%;
      margin-top: -1.1%;
      .login-form-box {
        width: 100%;
        height: 100%;
        padding: 8% 7% 1% 5%;
        .slide-verification {
          width: 310px;
          height: 176px;
          background: #ffffff;
          box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
          margin-top: -10px;
          margin-bottom: 6px;
          .slideVerification-text {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.75);
            display: flex;
            flex-direction: row;
            margin-top: -167px;
            position: fixed;
            justify-content: space-between;
            width:300px;
            .slideVerification-text-left {
              margin-left: 27px;
            }
            .slideVerification-text-right {
              margin-right: 25px;
            }
          }
          .slider-icon {
            background: url('../../../../asset/slider.svg') no-repeat;
            width: 13px;
            height: 10px;
          }
          :global {
            .rc-slider-captcha-embed {
              height: 176px;
              width: 310px !important;
              background: #ffffff;
              box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
              padding-bottom: 19px;
            }

            .rc-slider-captcha-control-tips {
              line-height: 7px;
              color: #cfcfd0;
            }
            .rc-slider-captcha-jigsaw {
              margin-top: 36px;
            }
            .rc-slider-captcha-control {
              min-height: 30px;
              height: 27px;
              line-height: 30px;
              background-color: #ecf0f7;
              margin-left: 20px !important;
              width: 270px;
            }
            .rc-slider-captcha-panel-inner {
              height: 125px !important;
              width: 310px;
              display: flex;
              justify-content: center;
            }
            .rc-slider-captcha-panel {
              padding-bottom: 10px !important;
            }
            .rc-slider-captcha-jigsaw-refresh {
              .rc-slider-captcha-icon {
                color: rgba(0, 0, 0, 0.75);
                position: fixed;
                width: 90px;
                font-size: 14px;
                padding-right: 30px;
                margin-left: -91px;
                margin-top: -30px;
              }
            }
          }
        }
        .get-code-button {
          position: relative;
          bottom: 1px;
          right: 100px;
          width: 100px;
          height: 40px;
          background: rgba(227, 231, 237, 0.3);
          border-radius: 4px;
          color: rgba(0, 0, 0, 0.85);
          box-shadow: none;
          .count-down {
            display: flex;
            position: relative;
            flex-direction: row;
          }
        }
        .login-form-item {
          display: flex;
          flex-direction: column;
          .form-group {
            height: 170px;
            padding-bottom: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .pass-group {
              display: flex;
            }
          }

          .login-error {
            width: 310px;
            height: 24px;
            line-height: 21px;
            background: rgba(241, 55, 55, 0.08);
            border-radius: 4px;
            border: 1px solid rgba(248, 154, 154, 1);
            font-size: 12px;
            color: rgba(0, 0, 0, 0.85);
            padding-left: 23px;
            position: relative;
            position: absolute;
            bottom: 85px;
            &:before {
              content: '';
              position: absolute;
              margin-top: 5.3px;
              margin-left: -15px;
              width: 12px;
              height: 12px;
              background: url('../../../../asset/iconError.png');
            }
          }
          .form-Bottom {
            display: flex;
            position: relative;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.55);
            align-items: flex-end;
            justify-content: flex-end;
            padding-right: 45px;
            :global{
              .ant-form-item-row{
                width: auto !important;
              }
            }
            .forget-pass-world {
              align-items: flex-end;
              cursor: pointer;
            }
          }

          :global {
            input:-webkit-autofill,
            textarea:-webkit-autofill,
            select:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 1000px rgb(247 248 250) inset !important;
              background-color: rgb(247 248 250) !important; //设置input框记住密码背景颜色
              background-image: none;
              transition: background-color 50000s ease-in-out 0s;
              -webkit-text-fill-color: rgba(0, 0, 0, 0.85) !important; //设置浏览器默认密码字体颜色
            }
            .ant-form-item-row {
              width: 310px;
            }
            .ant-form-item {
              margin-bottom: 0 !important;
            }
            .ant-input-affix-wrapper:focus {
              border: none;
              box-shadow: none !important;
            }
            .ant-form-item-explain-error {
              width: 310px;
              height: 24px;
              line-height: 23px;
              background: rgba(247, 167, 70, 0.08);
              border-radius: 4px;
              border: 1px solid #fbd2a1;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.85);
              padding-left: 23px;
              margin-top: 6px;
              position: relative;
              z-index: 9999;
              &:before {
                content: '';
                position: absolute;
                margin-top: 7px;
                margin-left: -14px;
                width: 12px;
                height: 10px;
                background: url('../../../../asset/warn.svg');
              }
            }

            .ant-input {
              line-height: 40px;
              background: transparent;
              border-radius: 4px;
              font-size: 13px;
              &::placeholder {
                color: rgba(0, 0, 0, 0.25) !important;
              }
            }
            .ant-input-prefix {
              color: #d6dbe1;
              margin-left: 15px !important;
              width: 15px;
            }
            .ant-input-affix-wrapper {
              display: inline-flex;
              width: 310px;
              height: 40px;
              line-height: 40px;
              border: none !important;
              background-color: rgba(227, 231, 237, 0.3) !important;
              border-radius: 4px;
              padding-left: 3px;
              // color: #fff;
              font-size: 15px;
            }
            .ant-input-affix-wrapper > input.ant-input {
              padding-left: 5px !important;
            }
            // input聚焦时
            .ant-input-affix-wrapper-focused {
              .anticon {
                color: #b1b4b9 !important;
              }
            }
          }
          .login-form-button {
            width: 310px;
            height: 40px;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 21.2%;
    display: flex;
    justify-content: center;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.25);
    padding-top: 54px;
  }
}
