// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.functional-tile-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  .left-list-box {
    width: 1178px;
    height: calc(100%);
    padding-top: 2px;
    padding-left: 19px;
    .left-list {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
    .left-list-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      .top-title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        margin-bottom: 8px;
        padding-left: 15px;
      }
      .activeTitle{
         color:var(--ant-primary-color);;
      }
      .list-item-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

    }
  }
  .right-anchor {
    width: calc(100% - 1178px);
    height: 100%;
    :global {
      .ant-anchor-wrapper {
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;
      }
      .ant-anchor {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;
      }
      .ant-anchor::-webkit-scrollbar {
        display: none;
      }
      .ant-anchor-wrapper .ant-anchor .ant-anchor-ink::before {
        display: none;
      }
      .ant-anchor-wrapper .ant-anchor .ant-anchor-link {
        padding-block: 0;
        padding-inline: 0;
      }
      .ant-anchor-link {
        width: 23px;
        height: 23px;
        text-align: center;
        line-height: 23px;
        margin-bottom: 2px;
        .ant-anchor-link-title {
          font-size: 13px;
          color: rgba(0,0,0,0.85);
        }
      }
      .ant-anchor-wrapper .ant-anchor .ant-anchor-link-active>.ant-anchor-link-title {
        color: #fff;
      }
      .ant-anchor-wrapper:not(.ant-anchor-horizontal) .ant-anchor .ant-anchor-ink {
        width: 23px;
        background: var(--ant-primary-color);
        border-radius: 4px;
        left: 2px;
      }
      .ant-anchor-wrapper:not(.ant-anchor-horizontal) .ant-anchor::before {
        display: none;
      }
    }
  }
  .many-anchor-class {
    :global {
      .ant-anchor {
        justify-content: flex-start;
      }
    }
  }
}
