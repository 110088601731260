// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.browserTipContainer{
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%,0);
    background: white;
    border-top: 5px solid orange;
    padding: 10px 40px;
    font-size: 12px;
    box-shadow: 2px 2px 5px #ccc;
    
    .text{
        font-weight: 600;
        white-space: nowrap;
    }
    .browsers{
        display: flex;
        align-items: center;
        white-space: nowrap;
        :first-child,:last-child{
            .icon{
                width: 18px;
                height: 18px;
                vertical-align: middle;
            }
        }

        .item{
            margin-left: 8px;
            :first-child{
                margin-right: 2px;
            }
            img{
                width: 15px;
                height: 15px;
                vertical-align: middle;
            }
        }
        .versionText{
            display: inline-block;
            vertical-align: middle;
        }
    }
    .warn{
        margin-left: 8px;;
        white-space: nowrap;
        :global{
            .ant-checkbox+span{
                padding-inline-start: 0px;
                font-size: 12px;
            }
        }
    }
    .close{
        margin-left: 8px;
        font-size: 22px;
        height: 24px;
        line-height: 20px;
        cursor: pointer;
    }
}

.noBrowserTipContainer{
    display: none;
}