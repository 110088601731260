// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.select:global(.ant-select.ant-select-single) {
  :global {
    .ant-select-selector {
      border-radius: 4px;
      border-color: #e3e7ed;
      height: 0.32rem;
      font-size: 0.14rem;
      padding: 0 0.2rem 0 0.1rem;

      .ant-select-selection-search {
        .ant-select-selection-search-input {
          height: 100%;
        }
      }

      .ant-select-selection-item {
        line-height: 0.3rem;
        padding-right: 0;
      }

      .ant-select-selection-placeholder {
        line-height: 0.3rem;
      }
    }

    .ant-select-arrow {
      font-size: 0.1rem;
      right: 8px;
    }
  }

  &:hover {
    :global {
      .ant-select-selector {
        border-color: #041f4a;
        box-shadow: 0 0 0 2px rgba(4, 31, 74, 0.08);
      }
    }
  }
}

// 获得焦点
.select:global(.ant-select.ant-select-single.ant-select-focused:not(.antd-select-disabled)) {
  :global {
    .ant-select-selector {
      border-color: #041f4a;
      box-shadow: 0 0 0 2px rgba(4, 31, 74, 0.08);
    }
  }
}

.select:global(.ant-select.ant-select-disabled) {
  :global {
    .ant-select-selector {
      background-color: #f3f5fa;
      color: #86909c;
    }
  }
}

.popupClassName {
  border-radius: 4px;

  :global {
    .rc-virtual-list {
      .rc-virtual-list-holder {
        .rc-virtual-list-holder-inner {
          .ant-select-item {
            font-weight: 400;
            font-size: 0.14rem;
          }
        }
      }
    }
  }
}