// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.functional-grouping-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  .menu-box {
    width: 240px;
    height: 100%;
    box-shadow: 0px 8px 10px 2px rgba(227,231,237,0.5);
    padding-right: 3px;
    padding-top: 17px;
    box-sizing: border-box;
  }
  .group-context-box {
    width: calc(100% - 240px);
    height: 100%;
    display: flex;
    flex-direction: row;
    .left-list-box {
      width: calc(100% - 190px);
      height: calc(100% - 84px);
      overflow-y: auto;
      margin-top: 30px;
    }
    .left-list {
      width: 100%;
      min-height: 100%;
      column-count: 3; // 定义三列
      column-gap: 20px; // 列与列的距离为20px
      .module {
        display: flex;
        flex-direction: column;
        width: 255px;
        padding: 0 10px 30px 10px;
        grid-row-start: auto;
        break-inside: avoid; // 不被截断
        .title {
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          margin-bottom: 10px;
          padding-left: 14px;
        }
        .activeTitle {
          color: var(--ant-primary-color);
        }
      }
    }

    // flex横向布局
    .flex-left-list {
      width: 100%;
      min-height: 100%;
      /* column-count: 3; */
      /* column-gap: 20px; */
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      .column-item{
        width: calc(100% / 3);
        .module{
          display: flex;
          flex-direction: column;
          height: fit-content;
          padding: 0 10px 30px 10px;
          .title {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            margin-bottom: 10px;
            padding-left: 14px;
          }
          .activeTitle {
            color: var(--ant-primary-color);
          }
          // .content:hover {
          //   background: var(--ant-primary-1) !important;
          //   color: var(--ant-primary-color) !important;
          // }
        }
      }
    }
    .right-anchor {
      width: 190px;
      height: 100%;
      border-left: 1px solid #E3E7ED;
      margin-left: 3px;
      margin-top: 30px;
      // .selectAnchorTitle {
      //   background: var(--ant-primary-color-outline);
      // }
      :global {
        .ant-anchor-wrapper:not(.ant-anchor-horizontal) .ant-anchor::before {
          display: none;
        }
        .ant-anchor-wrapper .ant-anchor .ant-anchor-ink::before {
          display: none;
        }
        .ant-anchor-wrapper:not(.ant-anchor-horizontal) .ant-anchor .ant-anchor-ink {
          width: 0;
          height: 0 !important;
          border-bottom: transparent 6px solid;
          border-left: var(--ant-primary-color) 7px solid;
          border-top: transparent 6px solid;
          background: rgba(0,0,0,0);
        }
        .ant-anchor-wrapper .ant-anchor .ant-anchor-ink-ball {
          width: 0;
          height: 0 !important;
          border-bottom: transparent 6px solid;
          border-left: var(--ant-primary-color) 7px solid;
          border-top: transparent 6px solid;
          background: rgba(0,0,0,0);
        }
        .ant-anchor-link-active {
          .ant-anchor-link-title {
            color: var(--ant-primary-color);
          }
        }
        .ant-anchor-link-title {
          font-size: var(--normal-font-size);
          color: rgba(0,0,0,0.85);
        }
        .ant-anchor-wrapper .ant-anchor .ant-anchor-link {
          padding-block: unset !important;
        }
        .ant-anchor-link {
          margin-bottom: 10px;
        }
      }
    }
  }
}