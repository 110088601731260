// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.function-switching-drawer {
  outline: none;
  top: 0 !important;
  z-index: 1;
  :global {
    .ant-drawer-header {
      display: none;
    }
    .ant-drawer-body {
      padding: 0;
      overflow: hidden;
    }
    .ant-drawer-content-wrapper {
      box-shadow: none !important;
      height: auto !important;
      top: 0 !important;
    }
    .ant-drawer-mask {
      background: rgba(0, 0, 0, 0.2);
    }
  }
  
  .go-back {
    position: absolute;
    top: 24px;
    right: 20px;
    text-align: center;
    white-space: nowrap;
    width: 80px;
    height: 34px;
    line-height: 34px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 13px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(227, 231, 237, 0.5);
    }
    .go-back-icon {
      margin-right: 10px;
      width: 6px;
      height: 10px;
      font-size: 10px;
      color: #999;
      margin-top: 2px;
    }
  }
}
