// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.SearchClassifyBox {
  width: 100%;
  height: 100%;
  // padding: 0 25px;

  .middlePanel {
    display: flex;
    width: 100%;
    height: 92%;
    padding: 10px 20px 0 25px;
    border-bottom: 1px solid #e2e7ed;

    .leftPart {
      padding-right: 20px;
    }

    .leftPart,
    .rightPart {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      margin-bottom: 30px;

      .classifyItem {
        width: 100%;
        height: 100%;
        margin-top: 30px;

        .titleRow {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 120px;
          color: rgba(0, 0, 0, 0.85);

          .classifyName {
            font-size: 14px;
            text-align: left;
          }

          .arrowIcon {
            margin-left: 5px;
            color: #bbbbbb;
            font-size: 10px;
          }
        }

        .titleRow:hover {
          // color: #fc9620;
          color:var(--ant-primary-color);

          .arrowIcon {
            // color: #fc9620;
            color:var(--ant-primary-color);
          }
        }

        .classifyContent {
          width: 100%;
          padding-top: 15px;
          text-align: center;

          .stayTuned{
            // height:150px;
            // line-height: 150px;
            color:rgba(0, 0, 0, 0.3);

          }
          .tableBodyRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            height: 30px;
            cursor: pointer;
          }

          .tableBodyRow:hover {
            background-color: rgba(227, 231, 237, 0.5);
          }

          .rowSelect {
            background-color: rgba(227, 231, 237, 0.3) !important;
          }

          .searchName {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.85);
            padding-left: 2px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            max-width: 170px;

            .innerText {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: block;
              max-width: 170px;
            }
          }

          .subName {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.3);
            justify-content: flex-end;
            display: flex;
          }

          .notFoundBlock {
            display: flex;
            align-items: baseline;

            .notFoundLabel {
              font-size: 13px;
              color: rgba(0, 0, 0, 0.45);
              margin-left: 10px;
            }

            .notIcon {
              color: rgba(0, 0, 0, 0.3);
              width: 17px;
              height: 17px;
            }
          }
        }
      }
    }

    .rightPart {
      .innerText {
        max-width: 210px !important;
      }
    }
  }

  .footerPanel {
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    margin: 14px 0;
    padding-left: 20px;

    .footerRow {
      display: flex;
      align-items: center;
      // height: 45px;
      // line-height: 45px;
      color: rgba(0, 0, 0, 0.85);

      .detailText {
        font-size: 13px;
        margin-right: 6px;
      }

      .arrowIcon {
        color: #bbbbbb;
        font-size: 10px;
      }
    }

    .footerRow:hover {
      color: #fc9620;

      .arrowIcon {
        color: #fc9620;
      }
    }
  }

  .visitPart {
    width: 100%;
    height: 220px;
    padding: 10px 20px;

    .visitName {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 16px;
    }

    .visitContent {
      width: 100%;
      height: 100%;
      padding-top: 16px;
      overflow-y: scroll;

      .tableBodyRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        height: 30px;
        cursor: pointer;
      }

      .tableBodyRow:hover {
        background-color: rgba(227, 231, 237, 0.6);
      }

      .rowSelect {
        background-color: rgba(227, 231, 237, 0.3) !important;
      }

      .searchName {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.85);
        padding-left: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      .notFoundBlock {
        display: flex;
        align-items: baseline;
        padding-bottom: 15px;

        .notFoundLabel {
          font-size: 13px;
          color: rgba(0, 0, 0, 0.45);
          margin-left: 10px;
        }

        .notIcon {
          color: rgba(0, 0, 0, 0.3);
          width: 17px;
          height: 17px;
        }
      }
    }

    .visitContent::-webkit-scrollbar {
      display: none;
      width: 0.5em;
      -webkit-appearance: none;
    }

    .visitContent::-webkit-scrollbar-thumb {
      background-color: rgba(40, 40, 66, 0.8);
      border-radius: 4px;
      -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
      box-shadow: 0 0 1px rgb(255 255 255 / 50%);
    }

    .visitContent::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }
  }

  .searchDetail {
    display: flex;
    height: 48px;
    font-size: 14px;
    font-family: PingFangSC-Regular;
    color: #1d2129;
    line-height: 48px;
    border-top: 1px solid #e3e7ed;
    padding-left: 20px;
    cursor: pointer;

    .arrowIcon {
      color: #bbbbbb;
      font-size: 10px;
      margin-left: 6px;
    }
  }

  .searchDetail:hover {
    // color: #fc9620;
    color:var(--ant-primary-color);

    .arrowIcon {
      // color: #fc9620;
      color:var(--ant-primary-color)
    }
  }
}
