// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.@{portal-prefix}-header-wrap-operate-btn{
  display: flex;
  padding:0 12px;
  .@{portal-prefix}-header-wrap-operate-btn-item{
    padding:0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    &:hover{
      background: @hd-bg-hover-color;
    }
  }
  :global{
    .ant-popover-inner{
      background: #FFFFFF;
      border: 0.5px solid rgba(227,231,237,1);
      box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
    }
  }
}
.downLoadContent{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 248px;
  height: 116.05px;
  padding-top: 20px;
  padding-bottom: 10px;
  .tooltip{

  }
  .buttonGroup{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 6px 0 70px;

    :global{
      .ant-btn{
        height: 24px;
        font-size: 14px;
        line-height: 15px;
        display: flex;
        justify-content: center;
      }
    }
  }

}