// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.recent-visits-item {
  max-width: 194px;
  height: 38px;
  line-height: 38px;
  padding: 0 16px;
  font-size: var(--normal-font-size);
  color: var(--ant-primary-color);
  background: rgba(227, 231, 237, 0.3);
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 0.5px solid rgba(227, 231, 237, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  .recent-visits-item-name {
    max-width: 147px;
  }
  .recent-visits-item-icon {
    margin-right: 7px;
    color: rgba(0, 0, 0, 0.85);
  }
  &:hover {
    background-color: rgba(227, 231, 237, 0.6);
  }
}
.recent-visits-item:hover {
  background: var(--ant-primary-color-outline);
}

.list-item {
  width: 220px;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-right: 46px;
  margin-bottom: 2px;
  cursor: pointer;
  .list-item-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    .list-item-icon {
      color: rgba(0, 0, 0, 0.65);
      width: 12px;
      height: 12px;
      margin-right: 6px;
    }

    .list-item-name {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .collection-icon {
    color: #ffbd00;
    font-size: 12px;
  }
  .hide {
    visibility: hidden;
  }
  &:hover {
    background: rgba(227, 231, 237, 0.6);
    .document-name {
      color: rgba(0, 0, 0, 0.85);
    }
    .hide {
      visibility: visible;
    }
  }
}

.module-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  font-size: 14px;
  margin-bottom: 2px;
  cursor: pointer;
  .document-name {
    color: rgba(66, 71, 77, 1);
  }
  .collection-icon {
    color: #ffbd00;
    font-size: 12px;
  }
  .hide {
    visibility: hidden;
  }

  &:hover {
    background: rgba(227, 231, 237, 0.6);
    .document-name {
      color: rgba(0, 0, 0, 0.85);
    }
    .hide {
      visibility: visible;
    }
  }
}

// flex横向布局
.flex-module-item {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  height: 34px;
  font-size: 14px;
  margin-bottom: 2px;
  cursor: pointer;
  .document-name {
    color: rgba(66, 71, 77, 1);
    display: flex;
    align-items: center;
    .iconSize{
      width: 14px;
      height: 14px;
    }
  }
  .collection-icon {
    color: #ffbd00;
    font-size: 12px;
  }
  .hide {
    visibility: hidden;
  }

  &:hover {
    // background: rgba(227, 231, 237, 0.6);
    background: var(--ant-primary-1);
    .document-name {
      // color: rgba(0, 0, 0, 0.85);
      color: var(--ant-primary-color);
    }
    .hide {
      visibility: visible;
    }
  }
}

.fuc-item-content-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
  margin-bottom: 17px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  .fuc-item-content-item-icon {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
}

.search-menu-item-title {
  display: flex;
  flex-direction: row;
  .menu-item-title-name-box {
    width: 160px;
    font-size: 13px;
  }
  .menu-item-collection-icon-box {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .collection-icon {
    color: #ffbd00;
    font-size: 12px !important;
    display: inline-block;
  }
  .hide {
    visibility: hidden;
  }
  &:hover {
    .hide {
      visibility: visible;
    }
  }
}

.collection-menu-item-title {
  display: flex;
  flex-direction: row;
  .menu-item-title-name-box {
    width: 160px;
  }
  .menu-item-close-icon-box {
    width: 30px;
    height: 10px;
    color: #cccccc;
    text-align: center;
    .menu-close-icon {
      display: inline-block;
      svg {
        width: 10px;
      }
    }
    visibility: hidden;
  }
  &:hover {
    .menu-item-close-icon-box {
      visibility: visible;
    }
  }
}

.activity-menuItem {
  color: var(--ant-primary-color) !important;
  // color: #000 !important;
}
.activity-backgroundItem {
  // background-color: #f6f7f9;
  background-color: var(--ant-primary-1) !important;

  .hide {
    visibility: visible;
  }
}

:global {
  .ant-collapse-header {
    padding: 6px 14px 6px 36px !important;
    margin-bottom: 2px;
  }

  .ant-collapse-content-box {
    padding: 0 14px !important;
  }
  .ant-collapse-expand-icon {
    margin-right: 4px;
    padding: 0 !important;
  }
}
