// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.historyNominate {
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #e2e7ed;

  .history {
    overflow: hidden;
    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .deleteBlock {
        :global {
          .ant-popover {
            z-index: 1070 !important;
          }
          .anticon svg{
            font-size: 15px;
            margin-top: -8px;
          }
          .ant-popconfirm .ant-popconfirm-message {
            font-size: 11px;
          }
        }
      }
      .title {
        height: 19px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 19px;
      }
      .arrowIcon {
        color: #404040;
        font-size: 14px;
        padding-right: 8px;
      }
    }
    .historyContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      min-height: 58px;
      max-height: 115px;
      font-size: 13px;
      padding-top: 6px;
      padding-bottom: 10px;
      overflow: hidden;
      .historyContentBox {
        max-width: 120px;
        width: fit-content;
        border-radius: 4px;
        margin: 16px 8px 5px 0;
        background: rgba(227, 231, 237, 0.3);
        // color: var(--ant-primary-color);
      }
      .historyContentBox:hover {
        // background: var(--ant-primary-color-outline) !important;
        background-color: var(--ant-primary-1) !important;
        color: var(--ant-primary-color) !important;
        border-color: var(--ant-primary-color) !important;
      }
      .notFoundKeyWord {
        display: flex;
        align-items: baseline;
        .notFoundLabel {
          font-size: 13px;
          color: rgba(0, 0, 0, 0.45);
          margin-left: 10px;
        }
        .notIcon {
          color: rgba(0, 0, 0, 0.3);
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}
.history {
  .historyContentBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
    border: 0.5px solid rgba(227, 231, 237, 0.3);
    background: rgba(227, 231, 237, 0.3);
  }
  .historyContentBox:hover {
    cursor: pointer;
    background:rgba(227, 231, 237, 0.6) !important;
  }
}
