// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.portal-login-container {
  width: 100%;
  background: url('../../asset/tinyLoginBg.jpg') no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-attachment: fixed;
  position: relative;

  .portal-login-topBox {
    height: 7.09%;
    padding: 30px 30px 0px 30px;
    min-width: 1280px;
  }
  .portal-login-middle {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex: 1 1 0%;
    .middle-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      margin: 0px auto;
      width: 1300px;
      height: 420px;
      .portal-login-middle-left-box {
        cursor: pointer;
        position: relative;
        margin-left: 50px;
        width: 450px;
        height: 100%;
        margin-right: 40px;
        color: transparent;
        background-color: transparent;
        .portal-login-banner-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          margin-top: 40px;
          .portal-login-banner-top-title {
            font-size: 30px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 15px;
          }
          .portal-login-banner-topLabel {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.55);
            margin-bottom: 15px;
          }
          .portal-login-banner-top-detail {
            display: flex;
            align-items: center;
            font-size: 13px;
            color: #df0024;
            cursor: pointer;
            .detailIcon {
              font-size: 12px;
              margin-left: 4px;
            }
          }
        }
      }
      .portal-login-middle-right-box {
        position: relative;
        width: 730px;
        // background-color: rgb(255, 255, 255);
        height: 100%;
        // overflow: hidden;

        .portal-login-middle-login-panel {
          width: 100%;
          height: 100%;
          position: relative;
          background: #ffffff;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
        }
      }
    }
  }

  .portal-login-footer-box {
    height: 8.98%;
    text-align: center;
    min-width: 1280px;
    .portal-login-footer-link-content {
      padding: 0;
      color: rgba(0, 0, 0, 0.25);
    }
    .portal-login-footer-link-content a {
      padding: 0 22px;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.55);
      cursor: pointer;
    }
    .portal-login-footer-link-content a:hover {
      color: #df0024;
    }
    .portal-login-footer-copy-right {
      margin-top: 14px;
      font-size: 11px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

@media all and (max-width: 1200px) {
  .portal-login-middle-left-box {
    display: none;
  }
}
