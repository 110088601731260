// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.@{portal-prefix}-header-wrap-operate-user-info {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 10px;
  margin-right: 7px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    border-left: 1px solid @split-line-color;
    left: 0;
    top: 8px;
    bottom: 8px;
  }

  .@{portal-prefix}-header-wrap-operate-user {
    display: flex;
    align-items: center;

    .@{portal-prefix}-header-wrap-operate-user-avator {
      width: 22px;
      height: 22px;
      margin-right: 10px;

      // img {
      //   width: 100%;
      // }
      .portalUserHeardPic {
        width: 25px;
        height: 25px;
        font-size: 6px;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 50%;
        // background: #1366ee;
        background: var(--ant-primary-color);
        color: #ffffff;
      }
    }

    .@{portal-prefix}-header-wrap-operate-user-menu {
      width: 75px;
    }

    .@{portal-prefix}-header-wrap-operate-user-name-dropdown-icon {
      font-size: 7px;
      margin-left: 12px;
    }
  }

  .@{portal-prefix}-user-container {
    .@{portal-prefix}-user-block {
      display: flex;
      align-items: baseline;

      .@{portal-prefix}-user-name {
        font-size: 12px;
      }

      .@{portal-prefix}-user-dropdown-icon {
        font-size: 7px;
        margin-left: 12px;
      }
    }
  }
}

.userDrawer {
  :global {
    .ant-drawer-mask {
      top: -44px;
      background: linear-gradient(transparent, transparent 44px, rgba(0, 0, 0, 0.45) 44px, rgba(0, 0, 0, 0.45));
    }

    .ant-drawer-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      height: 9.45rem;
      overflow: hidden;
    }
  }
}

.userDrawerWrapper {
  position: relative;
  width: 100%;
  // height: 9.1rem;
  height: 100%;
  padding: 0;
  padding: 20px 0 0 16px;
  background: linear-gradient(141deg, rgba(42, 131, 254, 0.1) 0%, #f6f6fc 23%, #f6f6fc 22%, #f6f6fc 1000%);

  .fixedBox {
    height: 78px;
    display: flex;
    flex-direction: column;
    padding-right: 16px;

    .logOut {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: 22px;
      font-size: 14px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #3d3f51;
      line-height: 22px;

      .logOutClick {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
      }
    }
    .logOut:hover {
      color: var(--ant-primary-color);
    }

    .userBox {
      display: flex;
      flex-direction: row;
      align-items: center;

      .imgBox {
        width: 56px;
        height: 56px;
        margin-right: 5px;
        // img {
        //   width: 56px;
        //   height: 56px;
        // }
        .portalUserHeardPic {
          width: 56px;
          height: 56px;
          justify-content: center;
          display: flex;
          align-items: center;
          border-radius: 50%;
          // background: #1366ee;
          background: var(--ant-primary-color);
          color: #ffffff;
        }
      }

      .nameBox {
        margin-left: 10px;
        display: flex;
        align-items: center;

        .name {
          height: 28px;
          font-size: 16px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #3d3f51;
          line-height: 28px;
        }

        .integralBox {
          width: 122px;
          height: 25px;
          background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
          border-radius: 13px;
          display: flex;
          align-items: center;
          padding-left: 12px;
          cursor: pointer;

          .integral {
            height: 20px;
            font-size: 12px;
            font-family:
              PingFangSC-Regular,
              PingFang SC;
            font-weight: 400;
            color: #757785;
            line-height: 20px;
            margin: 0 6px 0 4px;
            word-wrap: nowrap;
          }

          .jifenIcon {
            font-size: 12px;
          }

          .count {
            height: 20px;
            font-size: 12px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #3d3f51;
            line-height: 20px;
            margin-right: 17px;
          }

          .xiaLaIcon {
            font-size: 8px;
            color: #86909c;
          }
        }
      }
    }
  }

  .scrollBox {
    // height: 7.92rem;
    height: calc(100% - 78px);
    overflow: scroll;
    margin-top: 16px;
    margin-right: 5px;
    padding-right: 5px;
    padding-bottom: 30px;

    .whiteBox {
      background: #ffffff;
      margin-top: 16px;
    }

    .unitBox {
      position: relative;
      background: #ffffff;
      width: 100%;
      border-radius: 6px;
      padding: 0 16px;

      .unit {
        height: 54px;
        border: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        :global {
          .ant-select {
            width: calc(100% - 30px);
            height: 32px;
            background: white;
            margin-left: 10px;

            .ant-select-selector {
              background: white;
              border: none;
              box-shadow: none;
            }

            .ant-select-selection-item {
              font-size: 14px;
            }
          }
        }
      }

      .line {
        width: 2.56rem;
        height: 0.01rem;
        border-bottom: 0.01rem solid #eceeef;
      }
    }

    .settingBox {
      padding: 20px 16px;

      .title {
        height: 28px;
        font-size: 16px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #3d3f51;
        line-height: 28px;
      }

      .settingItemBox {
        list-style-type: none;
        padding-left: 0;

        .settingItem {
          height: 32px;
          font-size: 14px;
          font-family:
            PingFangSC-Regular,
            PingFang SC;
          font-weight: 400;
          color: #3d3f51;
          line-height: 22px;
          margin-top: 12px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .themeOption {
            display: flex;
            flex-direction: row;
            align-items: center;

            .themeBlue {
              background: #2a83fe;
            }

            .themeRed {
              background-color: #ff6245;
            }

            .themeYellow {
              background-color: #ffad3d;
            }

            .color {
              width: 10px;
              height: 10px;
              border-radius: 0.02rem;
              margin-right: 8px;
            }

            .themeOptionLabel {
              // width: 0.5rem;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .leftBox {
            display: flex;
            align-items: center;
            cursor: pointer;
            width: 100%;

            .settingIcon {
              font-size: 14px;
            }

            .settingName {
              margin-left: 4px;
              white-space: nowrap;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .clear-img {
              width: 14px;
              filter: brightness(0.5);
            }
          }
          .leftBox:hover {
            color: var(--ant-primary-color);
          }

          .themeSettingBox {
            max-width: 1.56rem;
          }
          .warning-img {
            width: 14px;
            height: 14px;
            margin-left: 6px;
            color: #FFA600;
          }

          :global {
            .ant-select {
              // width: 1.3rem;
              width: 160px;
              height: 32px;
              background: white;
              .ant-select-selector {
                border-radius: 4px;
                height: 32px;
                width: 160px;
                // border: 0.01rem solid #e3e7ed;
                background: white;
                box-shadow: none;
                font-size: 14px;
                font-family:
                  PingFangSC-Regular,
                  PingFang SC;
                font-weight: 400;
                color: #3d3f51;
                line-height: 0.14rem;
              }
            }
          }
        }
        // .settingItem:hover {
        //   // background: var(--ant-primary-color-outline);
        //   color: var(--ant-primary-color);
        // }
      }
    }

    .userSettingBox {
      border-radius: 6px;
    }

    .systemSettingBox {
      position: relative;
      height: 3.71rem;
      border-radius: 4px;
      padding-bottom: 0;
    }
    .settingCustom {
      width: 100%;
      height: 38px;
      border-radius: 4px;
      color: var(--ant-primary-color);
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .custom-icon {
        margin-right: 10px;
      }
    }
  }
}

:global {
  .ant-select-dropdown.theme-dropdown {
    .rc-virtual-list {
      .rc-virtual-list-holder {
        .rc-virtual-list-holder-inner {
          .ant-select-item {
            .ant-select-item-option-content {
              .dropdown-theme-option {
                display: flex;
                flex-direction: row;
                align-items: center;

                .dropdown-themeBlue {
                  background: #2a83fe;
                }

                .dropdown-themeRed {
                  background-color: #ff6245;
                }

                .dropdown-themeYellow {
                  background-color: #ffad3d;
                }

                .dropdown-color {
                  width: 10px;
                  height: 10px;
                  border-radius: 0.02rem;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}