// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.globalSearchContainer {
  width: 100%;
  :global {
    .ant-select .ant-select-selector {
      position: relative;
      background: #415576 !important;
      padding: 0 5px !important;
      padding-left: 18px !important;
      border: none !important;
      border-radius: 4px;
      &:before {
        content: '';
        position: absolute;
        left: 8px;
        left: 8px;
        left: 8px;
        top: 2.5px;
        width: 16px;
        height: 16px;
        color: #fff;
        background-size: 78% !important;
        margin-top: 4px;
        background: url('../../../../../../../asset/search.svg') no-repeat;
      }
      &:after {
        display: none;
      }
    }
    .ant-select-selector .ant-select-selection-placeholder {
      padding-left: 10px !important;
    }
    .ant-select .ant-select-clear {
      background: none !important;
      margin-right: 22px;
      color: rgba(255, 255, 255, 0.8);
    }
    .ant-select-multiple .ant-select-selector {
      border-radius: 16px !important;
    }
    .ant-select-selector .ant-select-selection-search,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        color: rgba(255, 255, 255, 0.8);
      height: 26px !important;
      width: 186px;
      margin-left: 10px;
      padding-inline-end: 34px
    }
    .ant-select-selection-search .ant-select-selection-search-input {
      color: rgba(255, 255, 255, 0.8);
    }
    .ant-select-multiple .ant-select-selection-item {
      color: rgba(255, 255, 255, 0.8);
      margin-left: 10px !important;
      margin-top: 5px;
      background: transparent;
      border: none;
      .ant-select-selection-item-content {
        width: 130px;
      }
    }
    .ant-select-multiple .ant-select-selection-overflow {
      width: 140px;
      height: 26px;
      overflow: hidden;
      flex-wrap: nowrap !important;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .ant-select-multiple .ant-select-selection-item-remove {
      color: rgba(255, 255, 255, 0.8);
      padding-top: 6px;
      display: none;
    }
    .ant-select:not(:where(.css-dev-only-do-not-override-18wc6to).ant-select-customize-input) .ant-select-selector {
      border: 1px solid #818fa4;
    }
    .ant-select-selector .ant-select-selection-placeholder {
      color: rgba(255, 255, 255, 0.8);
      font-size: 12px;
      padding-left: 4px;
    }
  }
}
.voiceIcon {
  margin-bottom: 5.5px;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
}
.selectorDropdown {
  width: 563px !important;
  padding: 0px !important;
  left: auto !important;
  right: 15px !important;
  top: 44px !important;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
}

.dropdownPanel {
  height: 100%;
  .loadingBox {
    width: 563px;
    height: 614px;
    position: relative;
  }
  .loadingIcon {
    position: absolute;
    top: 307px;
    left: 280px;
  }
}
.searchPanel {
  .searchPanelHeader {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }
  .searchPanelContent {
    border-radius: 2px;
  }
}

.globalSearchContainerOtherThem {
  :global {
    .ant-select-selector .ant-select-selection-search, .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
      color: inherit;
    }
    .ant-select .ant-select-selector {
      background: rgba(227, 231, 237, 0.5) !important;
      border: 0 !important;
      &:before {
        background: url('../../../../../../../asset/blackSearch.svg') no-repeat;
      }
    }
    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.25) !important;
    }
    .ant-select-arrow {
      display: none;
    }
  }
}
