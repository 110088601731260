// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.custom-edit-layout {
  height: 100vh;

  .custom-edit-header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 0 15px 0 20px;
    .head-left {
      font-size: 16px;
      display: flex;
      align-items: center;
      .icon-select {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 56px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        font-size: 12px;
        padding: 0 12px;
        color: rgba(0, 0, 0, 0.85);
        flex-shrink: 0;
        cursor: pointer;
        &:hover {
          // border-color: var(--ant-primary-5);
          border: 1px solid var(--input-border-color);

          box-shadow: 0 0 0 2px var(--input-shadow);
        }
        .icon-down {
          color: #999999;
        }
      }
      .title {
        line-height: 1;
      }
    }
    h3 {
      flex: 1;
      text-align: center;
      line-height: 50px;
      margin: 0;
      font-size: var(--root-font-size);
      color: rgba(0, 0, 0, 0.55);
    }
    .head-right {
      display: flex;
      align-items: center;
      [type='button'] {
        margin-left: 15px;
        font-size: var(--root-font-size);
        width: 54px;
        height: 28px;
        padding: 0;
      }
    }
  }
  .custom-edit-sider {
    border-top: 1px solid #eeeeee;
    background-color: #ffffff;
    padding: 20px 20px 25px;
    margin: 10px 0 12px 10px;
    .custom-edit-sider-select {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 12px;
      width: 100%;
      :global(.ant-select) {
        width: 100%;
      }
    }
    .sider-collapse {
      margin-top: 4px;
      :global {
        .ant-collapse-header {
          padding: 16px 0 15px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          .ant-collapse-arrow {
            color: #999999;
          }
        }
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0 0 14px 0;
        color: rgba(0, 0, 0, 0.85);
        // color: var(--ant-primary-color);
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        height: 34px;
        line-height: 34px;
        font-size: var(--root-font-size);
        background: rgba(227, 231, 237, 0.3);
        border-radius: 4px;
        
        &:hover {
          // background: var(--ant-primary-color-outline);
          background-color: var(--ant-primary-1);
          color: var(--ant-primary-color)
        }
        &.selected {
          // background-color: var(--antd-primary-bg-base);
          background-color: var(--ant-primary-1);
          color: var(--ant-primary-color)
        }
        .anticon {
          font-size: 16px;
        }
      }
    }
  }
  .custom-edit-content {
    // background-color: #f5f7f9;
    width: 100%;
    height: 100%;
    overflow: auto;
    // padding: 5px;
    .content-empty {
      width: 100%;
      height: 100%;
      padding: 10px;
      // padding-left: 10px;
      .content-empty-wapper {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      p {
        text-align: center;
        margin-bottom: 0;
        margin-top: 29px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.55);
        font-size: var(--root-font-size);
      }
    }
  }

  :global {

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border: 1px solid var(--ant-primary-color);

      box-shadow: 0 0 0 2px var(--input-shadow);
    }
    .ant-layout.ant-layout-has-sider {
      background: var(--background-url) no-repeat center / cover;
    }
    .ant-select .ant-select-selector {
      height: 28px;
      .ant-select-selection-search-input {
        height: 26px;
        line-height: 26px;
      }
      .ant-select-selection-placeholder {
        line-height: 26px;
      }
    }
    .react-grid-item.react-grid-placeholder {
      background: rgba(255, 102, 102, 0.25);
      opacity: 1;
      border: 1px dashed #ff3232;
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
      z-index: 10;
    }
    .react-grid-layout {
      background-color: transparent;
      // margin-top: -6px;
    }
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
      .ant-select-selector {
      background: var(--select-border-background) !important;
      border-color: var(--ant-primary-color) !important;
      color: rgba(29, 33, 41, 1);
      box-shadow: none !important;
    }
    .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      )
    .ant-select-selector {
      box-shadow: none !important;
      border-color: var(--ant-primary-4) !important;
    }
    .ant-collapse-content-box li{
      &:hover{
        background-color: var(--ant-primary-1) !important;
        color: var(--ant-primary-color) !important;
      }
    }
    .ant-dropdown-trigger{
      &:hover{
        border: 1px solid var(--ant-primary-4) !important;
      }
      // border: 1px solid var(--ant-primary-4) !important;
      // color: #fff !important;
    }

  }
}

.grid-item {
  background: #fff;
  height: 100%;
  overflow: hidden;
  border-radius: 6px;
  .grid-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .item-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9;
      :global {
        .anticon {
          color: #cccccc;
          font-size: 9px;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

//首页样式
.custom-dashboard {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  background: var(--background-url) no-repeat center / cover;
  .custom-dashboard-header {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    height: 50px;
    width: 100%;
    line-height: 50px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    .header-left {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      .iconFont {
        margin-top: 18px;
        margin-right: 10px;
        width: 16px;
        height: 16px;
      }
      .title {
        font-size: 16px;
        font-weight: 550;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  .custom-dashboard-sider {
    display: flex;
    justify-content: space-between;

    width: 240px;
    height: 100%;
    padding: 10px 0 12px 10px;
    background: rgba(0, 0, 0, 0);
    // height: calc(100vh - 51px);
    // margin-top: 2px;
  }
  .custom-dashboard-content {
    display: flex;
    // padding: 10px;
    justify-content: space-between;
    // background: #f5f7f9;
    height: calc(100vh - 51px);
    width: calc(100vw - 240px);
  }
  :global {
    .ant-layout .ant-layout-sider-children {
      background-color: #fff;
    }
    .ant-layout {
      background: rgba(0, 0, 0, 0);
    }
    .ant-layout-header {
      padding-inline: 32px !important;
    }
    .ant-tree {
      // margin-top: 10px;
    }
    .ant-layout.ant-layout-has-sider > .ant-layout {
      padding: 0 !important;
    }
  }
}
.selectStyles {
  // background-color: #000;
  :global {
    .ant-select-item-option-active,
    .ant-select-item-option-selected {
      background-color: var(--ant-primary-1) !important;
      color: var(--ant-primary-color) !important;
    }
  }
}

:global {
  .ant-dropdown-menu-item{
    &:hover{
      background-color: var(--ant-primary-1) !important;
      color: var(--ant-primary-color) !important;
    }
  }
}
