// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.aside {
  display: flex;
  height: 100%;
  flex-direction: column;
  transition: all 0.2s ease 0s;
  box-shadow: 0px 8px 10px 2px rgba(227, 231, 237, 0.5);
  background: #fff;
  margin-right:10px;
  .collapse{
    display: flex;
    height: 40px;
    padding-left: 20px;
    padding-right: 22px;
		padding: 0 22px 7px 25px;
    align-items: center;
    .icon{
      width: 16px;
    }
  }
	.collectSearch{
		padding: 16px 0 0 20px;
		.search-icon {
				font-size: var(--normal-font-size);
				color: #BFBFBF;
				height: 40px;
			}
	}
  .collectMenu{
    width: 100%;
    height: calc(100% - 100px);
  }
}
.collapseTooltip {
	:global {
		.ant-tooltip-inner {
			white-space: nowrap !important;
			padding: 6px 12px;
			color: #fff;
			font-size: var(--normal-font-size);
		}
	}
}