// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.menu-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .iconSize{
    width: 14px;
    height: 14px;
  }
  
  :global {
    .ant-menu-item::before {
      display: none !important;
    }
    .ant-menu-inline,
    .ant-menu-vertical {
      border-inline-end: none !important;
    }
    .ant-drawer {
      width: 100vw;

      .ant-drawer-body {
        padding: 0;
      }
    }
  }
  .line {
    margin: 0 20px 20px;
    width: calc(100% - 40px);
    height: 1px;
    background: #e3e7ed;
  }
  .my-collection {
    position: relative;
    padding-left: 20px;
    display: flex;
    align-items: center;
    height: 20px;
    margin-bottom: 20px;

    .my-collection-icon {
      margin-right: 8px;
      font-size: 16px;
      display: inline-block;
    }
    .my-collection-title {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }

    .collect-right {
      display: flex;
      flex: auto;
      width: 100%;
      max-width: 100%;
      min-width: 0;
      overflow: hidden;
      align-items: center;
      white-space: nowrap;
      padding-right: 20px;
      justify-content: space-between;
    }

    .my-top-icon {
      color: initial;
    }
  }
  .nail-padding {
    padding: 20px 0 11px 19px;
  }
  .nail {
    width: 25px;
    height: 25px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;

    &:hover {
      background-color: #ebedf1;
    }
  }
  .all-functions {
    white-space: nowrap;
    height: 64px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    padding: 20px;
    cursor: pointer;
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    // color: #0e42d2;
    color: var(--ant-primary-color) !important;
    // &:hover {
    //   background: rgba(227,231,237,0.5);
    // }
    .all-functions-icon {
      margin-right: 10px;
      display: inline-block;
      // color: #999999;
    }
    .all-fun-right {
      font-size: 14px;
      float: right;
      padding-top: 5px;
      color: #86909c;
    }
  }
  .left-menu {
    width: 240px;
    margin-top: -38px;
    padding-top: 20px;
    height: calc(100vh - 50px);
    box-shadow: 0px 8px 10px 2px rgba(227, 231, 237, 0.5);
  }
}
.to-top-tooltip {
  left: 60px !important;
  :global {
    .ant-tooltip-inner {
      white-space: nowrap !important;
      padding: 6px 12px;
      color: #fff;
      font-size: var(--normal-font-size);
    }
  }
}
