// The prefix to use on all css classes.
@portal-prefix: portal;

// globalFont
// 存放字体
@font-family: pingfang SC, Microsoft YaHei, Noto Sans SC;
// 存放字体大小变量
// 一级标题
@font_size_first_level: 18px;
@first_font: @font_size_first_level / 28px @font-family;
// 二级标题
@font_size_second_level: 16px;
@second_font: @font_size_second_level / 24px @font-family;
// 三级标题
@font_size_three_level: 14px;
@three_font: @font_size_three_level / 22px @font-family;
// 常规文字
@font_size_normal_level: 13px;
@normal_font: @font_size_normal_level / 22px @font-family;
// 辅助文字
@font-size-ass_level: 12px;
@ass_font: @font_size_normal_level / 20px @font-family;

// bgColor
@portal-main-layout-bg-color: #f5f7f9;
@hd-bg-color: #041f4a;
@hd-bg-hover-color: rgba(255, 255, 255, 0.25);
@hd-tags-home-bg-color: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color: rgba(255, 255, 255, 1);
// splitline-color
@split-line-color: rgba(255, 255, 255, 0.6);

@portal-main-layout-bg-color-other: #f5f7f9;
@hd-bg-color-other: rgba(255, 255, 255, 1);
@hd-bg-hover-color-other: rgba(4, 31, 74, 0.05);
@hd-tags-home-bg-color-other: rgba(227, 231, 237, 0.6);
@hd-tags-bg-color-other: rgba(227, 231, 237, 0.35);
// splitline-color
@split-line-color-other: rgba(255, 255, 255, 0.6);
.low-browser-container {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  .low-browser-left {
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    .rocket-image {
      width: 377px;
      height: 302px;
      margin-left: 10px;
      background: url('../../../src/asset/browser/rocket.png') no-repeat;
    }
  }
  .low-browser-right {
    width: 65%;
    height: 100%;
    display: flex;
    align-items: center;

    .browserContainer {
      margin-left: 50px;
      padding-bottom: 50px;
      .browser-title {
        width: 456px;
        height: 33px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 33px;
        text-align: left;
        font-style: normal;
      }
      .browser-second-title {
        width: 364px;
        height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin: 20px 0px;
      }
      .browser-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .browser-item {
          height: 70px;
          width: 90px;
          margin-right: 20px;
          .browser-icon {
            width: 52px;
            height: 52px;
            margin-left: 18px;
          }
          .browser-item-title {
            width: 100px;
            height: 17px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 17px;
            text-align: left;
            font-style: normal;
            margin-bottom: 10px;
          }
          .browser-item-btn {
            width: 60px;
            height: 32px;
            background: #00ac75;
            border-radius: 4px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #ffffff;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
